/* This rule is already applied by ant/tinycolor.less
html, body {
  width: 100%;
  height: 100%;
} */

#root {
  height: 100%;
  position: relative;
  z-index: 1;
}

#root .ant-layout{
  min-height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body::-webkit-scrollbar {
  width: 5px;
}

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #d9d9d9;
}

body::-webkit-scrollbar-thumb {
  background-color: #d9d9d9;
  border-radius: 23px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

.ant-layout {
  background: #fff !important;
}

.ant-layout-header {
  height: 50px !important;
  line-height: 50px !important;
}

.ant-layout-footer {
  background: #fff !important;
}

div[role="menuitem"].ant-menu-submenu-title {
  padding-left: 16px !important;
}

li[role="menuitem"].ant-menu-item {
  padding-left: 24px !important;
}

aside.ant-layout-sider.ant-layout-sider-dark {
  flex: 0 0 211px !important;
  max-width: 211px !important;
  min-width: 211px !important;
  width: 211px !important;
}

.ant-list-pagination {
  margin-bottom: 24px;
}

.ant-card-head {
  min-height: 30px;
}

.loading-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* disable the center align from ant Spin */
  text-align: initial;
  /* Currently the sidebar may cause page to extend far down vertically */
  max-height: 100vh;
}
.loading-container > div {
  position: relative;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  /* debugging whether the svg fits */
  /* background-color: yellow; */
}
.loading-container .loading-static {
  position: absolute;
  z-index: 2;
}
.loading-container .loading-static svg{
  display: block;
  width: 40%;
  height: 40%;
  position: relative;
  top: -6%;
  left: 11%;
}
.loading-container .loading-animated {
  position: absolute;
  z-index: 1;
  /* loadingcircle is defined by Ant stylesheets */
  animation: loadingCircle 2s infinite linear;
}
.loading-container .loading-animated svg{
  width: 100%;
  height: 100%;
}

.VideosList_search-input {
  top: 24px;
}

.ant-select .ant-select-single .ant-select-show-arrow > .ant-select-selector {
  text-align: justify;
}

.ant-skeleton-content .ant-skeleton-title {
  background-color: #d9d9d9 !important;
}

.ant-skeleton-content .ant-skeleton-paragraph > li {
  background-color: #d9d9d9 !important;
}

.ant-breadcrumb > span {
  cursor: pointer;
}

.ant-card-type-inner > .ant-card-head {
  background-color: #f0f0f0 !important;
  font-size: 14px;
  font-weight: 500;
}

.ant-picker-suffix {
  display: none;
}

.date-picker-listview{
  display: flex;
  gap: 10px;
  align-items: baseline;
  padding: 10px 0;
}

.Blank_active_tab > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
  border: none !important;
}

.Blank_active_tab.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  border-bottom: none !important;
}

.Wrike_TaskLists .ant-table-thead {
  font-size: 0.8rem;
}
.Wrike_TaskLists .ant-table-tbody {
  font-size: 0.8rem;
}

/*
.Wrike_TaskLists .ant-table-cell .ant-table-row-expand-icon-cell {
}
*/

.AlarmStatistics_Alert .ant-alert-message {
  font-size: 0.8rem !important;
}

.AlarmsList_Table .ant-table-thead {
  font-size: 0.8rem;
}

.AlarmsList_Table .ant-table-tbody .ant-table-cell {
  padding: 0;
}
.AlarmsList_Table .ant-table-tbody > tr > td {
  border-bottom: none;
}

/* .AlarmsList-Table > .AlarmStatistics_Alert {
  margin: 0;
} */
.RobotStatsCard.ant-card {
  border: 1px solid #f0f0f0;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  margin: 0 16px;
}
.RobotStatsCard .ant-card-body {
  padding: 8px 16px;
}

.RobotStats_Content,
.RobotStats_Title {
  text-align: left;
}

.RobotStats_Content .ant-statistic-title,
.RobotStats_Title .ant-statistic-content {
  display: none;
}

.RobotStats_Content .ant-statistic-content {
  font-weight: 700;
  font-size: 28px;
  color: #3076b8;
  letter-spacing: -2%;
}

.RobotStats_Title .ant-statistic-title {
  font-weight: 500;
  font-size: 14px;
}

.ant-modal-root {
  position: fixed;
  z-index: 2;
}

.ant-modal {
  border-radius: 8px;
}

.videos-reports-download {
  color: rgba(0, 0, 0, 0.45);
}

.videos-reports-download.download-button-big > .anticon{
  color: #1890ff;
}

.videos-reports-download.download-button-big > .file-size{
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}

.ant-modal-wrap > .WeldVideos_Modal.ant-modal {
  width: 73.75vw !important;
  height: 78.89vh !important;
}
.ant-modal-wrap > .WeldVideos_Modal.ant-modal .ant-modal-content {
  border-radius: 8px !important;
}
.ant-modal-wrap > .WeldVideos_Modal.ant-modal .ant-modal-header {
  border-radius: 8px !important;
}
.ant-modal-wrap > .WeldVideos_Modal.ant-modal .ant-modal-title {
  font-weight: 700 !important;
  font-size: 20px !important;
}
.WeldVideos_Modal .weld-files-daily-report-item .ant-list-item-action{
  margin-left: 10px;
}
.WeldVideos_Modal .weld-files-daily-report-item.ant-list-item{
  font-size: 14px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.45);
}

.ant-modal.weld-file-change-modal{
  /*ant likes to auto set a hardcoded width value*/
  width: 80% !important;
}
.ant-modal.weld-file-change-modal .ant-modal-body{
  display: grid;
  grid-template-rows: 200px 1fr;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}
.ant-modal.weld-file-change-modal .ant-modal-body :nth-child(1){
  grid-area: 1/1/2/3;
}
.ant-modal.weld-file-change-modal .ant-modal-body :nth-child(2){
  grid-area: 2/1/3/2;
}
.ant-modal.weld-file-change-modal .ant-modal-body :nth-child(3){
  grid-area: 2/2/3/3;
}

.WeldHistory_Card .ant-card-body {
  padding: 0 !important;
}

.WeldHistoryList_Table .expand-parent {
  background-color: #d9d9d9;
}
.WeldHistoryList_Table .WeldHistoryList_Table_Nested {
  border: solid #d9d9d9 2px;
  border-top: none;
  padding: 8px 0 !important;
  border-collapse: collapse !important;
}

.WeldHistoryList_Table
.ant-table-expanded-row.ant-table-expanded-row-level-1
> .ant-table-cell {
  padding: 0px;
}

.WeldHistoryList_Table .ant-table-thead {
  font-size: 0.8rem;
}

.WeldHistoryList_Table .ant-table-thead > tr > th {
  text-align: left !important;
  background-color: #fff !important;
  border: none !important;
}

.WeldHistoryList_Table .ant-table-tbody > tr > td {
  border-bottom: none;
}
.ant-modal-wrap > .WeldTestReport_Modal.ant-modal {
  width: 50vw !important;
}

.WeldTestReport_Button_Pass.ant-radio-button-wrapper
.ant-radio-button.ant-radio-button-checked {
  background: #52c41a;
  border-color: #52c41a;
}

.WeldTestReport_Button_Fail.ant-radio-button-wrapper
.ant-radio-button.ant-radio-button-checked {
  background: #f5222d;
  border-color: #f5222d;
}

.WeldTestReport-DefectType_button_label > div > label {
  display: none;
}

.analytics-page .ant-card{
  outline: 1px solid #f0f0f0;
}
.analytics-page .weld-activity-download-buttons{
  width: 144px;
  height: 33px;
  display: flex;
  gap: 8px;
}
.analytics-page .weld-activity-download-buttons button{
  display: flex;
  align-items: center;
  padding: 8px 10px;
  gap: 7px;
  border: 1px solid rgba(24, 144, 255, 0.3);
  border-radius: 2px;
  font-weight: 500;
}
.analytics-page .weld-activity-download-buttons button svg{
  width: 11px;
  height: 11px;
}
.analytics-page .weld-activity-download-buttons button:nth-child(1){
  width: 64px;
  background: #3076B8;
  color: #FFFFFF;
}
.analytics-page .weld-activity-download-buttons button:nth-child(2){
  width: 72px;
  background: #FFFFFF;
  color: #1890FF;
}
.analytics-page .weld-activity-download-buttons .ant-btn span{
  margin: 0;
  line-height: 1;
}

.analytics-page .support-robot-wrike-stats .ant-card{
  outline: none;
}

/* snug-fit table into the ant card */
.analytics-page .support-robot-wrike-tickets >.ant-card-body{
  padding: 0;
}

/* subtract the height of the header */
/* note - hardcoded value */
.support-robot-detail > .ant-card-body,
.support-robot-wrike-tickets > .ant-card-body
{
  height: calc(100% - 39px);
}
/* select everything between the card body and table body */
.support-robot-detail,
.support-robot-detail > .ant-card-body > .ant-table-wrapper,
.support-robot-detail > .ant-card-body > .ant-table-wrapper > .ant-spin-nested-loading,
.support-robot-detail > .ant-card-body > .ant-table-wrapper > .ant-spin-nested-loading > .ant-spin-container,
.support-robot-detail > .ant-card-body > .ant-table-wrapper .ant-table,
.support-robot-detail > .ant-card-body > .ant-table-wrapper .ant-table > .ant-table-container,
.support-robot-wrike-tickets,
.support-robot-wrike-tickets > .ant-card-body > .ant-table-wrapper,
.support-robot-wrike-tickets > .ant-card-body > .ant-table-wrapper > .ant-spin-nested-loading,
.support-robot-wrike-tickets > .ant-card-body > .ant-table-wrapper > .ant-spin-nested-loading > .ant-spin-container,
.support-robot-wrike-tickets > .ant-card-body > .ant-table-wrapper .ant-table,
.support-robot-wrike-tickets > .ant-card-body > .ant-table-wrapper .ant-table > .ant-table-container
{
  height: 100%;
}

/* make the table body fit the entire space even when not full */
.support-robot-detail > .ant-card-body > .ant-table-wrapper .ant-table > .ant-table-container,
.support-robot-wrike-tickets > .ant-card-body > .ant-table-wrapper .ant-table > .ant-table-container
{
  display: flex;
  flex-direction: column;
}
.support-robot-detail > .ant-card-body > .ant-table-wrapper .ant-table > .ant-table-container > .ant-table-body,
.support-robot-wrike-tickets > .ant-card-body > .ant-table-wrapper .ant-table > .ant-table-container > .ant-table-body
{
  flex-grow: 1;
}

.support-robot-wrike-tickets > .ant-card-body > .ant-table-wrapper .ant-table > .ant-table-container > .ant-table-body
{
  /* experimentally measured on analytics page */
  /* override the 180px setting from dashboard page */
  max-height: 237px !important;
}

/* div adds specificity making this selector 0-2-1, */
/* to override existing selectors at 0-2-0 such as tinyColor.less:(.ant-card-small > .ant-card-body) */
.support-robot-detail div.ant-card-body{
  padding: 0;
}
.support-robot-detail div.ant-table-body{
  max-height: 100%;
}
.support-robot-detail .ant-table-thead th.ant-table-cell{
  padding: 0;
}
.SWRDetailList_Table {
  overflow: auto;
}
.SWRDetailList_Table .ant-table-thead {
  font-size: 0.8rem;
}
.SWRDetailList_Table .ant-table-thead > tr > th {
  text-align: center !important;
}
.SWRDetailList_Table .ant-table-tbody .ant-table-cell {
  padding: 0.4rem;
  font-size: 0.8rem;
}
.SWRDetailList_Table .ant-table-tbody > tr > td {
  border-bottom: none;
}

.marker-cluster-map {
  height: 100%;
  width: 100%;
}

.swr-distribution-chart{
  height: 300px;
  width: 100%;
  overflow: hidden;
}
.swr-distribution-chart svg{
  position: relative;
  transform: scale(1.2);
}

.leaflet-control-attribution.leaflet-control {
  display: none;
}

div.leaflet-pane.leaflet-map-pane > div.leaflet-pane.leaflet-tile-pane {
  filter: grayscale(1);
}
.marker-cluster-small {
  margin-left: -20px;
  margin-top: -20px;
  width: 40px;
  height: 40px;
  transform: translate3d(394px, 217px, 0px);
  z-index: 217;
  outline: none;
  border-radius: 20px;
}
.marker-cluster-small div {
  width: 30px;
  height: 30px;
  margin-left: 5px;
  margin-top: 5px;
  text-align: center;
  border-radius: 15px;
  font-size: 12px;
}

/* full calendar css */
.fc .fc-button {
  text-transform: capitalize !important;
  background: white !important;
  color: black !important;
  border-radius: 0 !important;
  border: none !important;
}
.fc .fc-button.fc-prev-button, .fc .fc-button.fc-next-button{
  padding: 0;
  margin: 0 2.5px;
}
.fc .fc-button.fc-prev-button:focus, .fc .fc-button.fc-next-button:focus{
  outline: none !important;
  box-shadow: inset 0 0 0 0.2rem rgba(76, 91, 106, 0.5) !important;
}

.fc-toolbar-chunk div {
  display: flex;
}

.fc .fc-daygrid-day-top {
  flex-direction: row !important;
}

.fc .fc-button.fc-today-button {
  border: 1px solid #f0f0f0 !important;
  font-size: 14px;
  line-height: 1;
  padding: 7px 8px;
}

.fc-direction-ltr .fc-button-group > .fc-button:not(:first-child) {
  border: none !important;
}
/*
.fc-goToDate-button.fc-button.fc-button-primary {
  border-radius: 0 !important;
  color: #fff !important;
  background: #1890ff !important;
  border-color: #1890ff !important;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%) !important;
  box-shadow: 0 2px #0000000b !important;
  padding: 0.093em;
}
*/
.fc-h-event {
  border-radius: 0 !important;
  border: none !important;
  background: #f0f0f0 !important;
  color: black !important;
}

.fc .fc-daygrid-day-number {
  color: black !important;
}

.fc-daygrid-event-harness .fc-daygrid-event-harness-abs {
  color: black !important;
}

.fc-daygrid-event-harness {
  border-left: #1890ff solid 2px;
}

.fc-daygrid-event-dot {
  display: none;
}

.fc-daygrid-event
.fc-daygrid-dot-event
.fc-event
.fc-event-start
.fc-event-end
.fc-event-past {
  color: black !important;
}

.fc .fc-daygrid-day-frame {
  min-height: 92px !important;
}

.fc-direction-ltr .fc-daygrid-event.fc-event-start{
  margin-left: 0 !important;
}

.fc-direction-ltr .fc-daygrid-event .fc-event-time {
  font-weight: 700;
  color: black;
}
.fc-daygrid-dot-event .fc-event-title {
  color: black;
  font-weight: 400 !important;
}

.fc-h-event .fc-event-title {
  color: black;
  font-weight: 400;
}

.fc .fc-daygrid-day.fc-day-today {
  background: transparent !important;
  color: #3076b8 !important;
  border-top: #3076b8 solid 2px;
}

.fc .fc-daygrid-day-bottom {
  font-weight: 700 !important;
  text-align: center !important;
}
.fc .fc-daygrid-day-bottom a {
  color: black !important;
}

.fc .fc-daygrid-day.fc-day-today a {
  color: #3076b8 !important;
  font-weight: 600 !important;
}

.fc th {
  text-align: left !important;
  text-transform: uppercase !important;
  font-weight: 400 !important;
}
.fc th a {
  color: black !important;
}

.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 19px !important;
}
.fc h2.fc-toolbar-title {
  display: flex;
  align-items: flex-end;
  font-size: 18px;
  font-weight: 600;
}

.fc-goToDate-button.fc-button.fc-button-primary .ant-picker .ant-picker-input {
  width: 100% !important;
}

.ant-picker-cell .ant-picker-cell-inner.circled-date{
  border: 1px solid #1890ff;
  border-radius: 50%;
}

.calendar-container{
  position: relative;
  z-index: 2;
}

.calendar-container > .fc{
  position: relative;
  z-index: 3;
}

.calendar-container .fc-popover{
  position: relative;
  z-index: 5;
}

.calendar-alert-overlay{
  position: absolute;
  top: 7px;
  z-index: 4;
  display: grid;
  grid-template-columns: repeat(6, min-content);
  white-space: nowrap;
  grid-gap: 6px;
  width: 600px;
  height: 26px;
  margin: 2px 0;
  justify-content: center;
  font-size: 12px;
  font-weight: 400;
  color: #595959;
}
.calendar-alert-overlay > button{
  all:unset;
  display: flex;
  align-items: center;
  padding: 2px;
  height: inherit;
  cursor: pointer;
}
.calendar-alert-overlay > button.inactive{
  color: #8c8c8c !important;
  background-color: #fafafa !important;
}
.calendar-alert-overlay span.value{
  font-size: 16px;
  font-weight: 500;
}
.calendar-alert-overlay span:nth-child(2){
  padding-left: 8px;
}
.calendar-alert-overlay h1{
  display: flex;
  align-items: center;
  background-color: white;
  border: 1px solid transparent;
  margin: 0;
  font-size: inherit;
  font-weight: inherit;
}
.calendar-alert-overlay .calendar-alert-green{
  background-color: #f6ffed;
  border: 1px solid transparent;
}
.calendar-alert-overlay .calendar-alert-green:hover, .calendar-alert-green.active{
  border: 1px solid #b7eb8f;
}
.calendar-alert-overlay .calendar-alert-red{
  background-color: #fff1f0;
  border: 1px solid transparent;
}
.calendar-alert-overlay .calendar-alert-red:hover, .calendar-alert-red.active{
  border: 1px solid #ffa39e;
}
.calendar-alert-overlay .calendar-alert-yellow{
  background-color: #fff7e6;
  border: 1px solid transparent;
}
.calendar-alert-overlay .calendar-alert-yellow:hover, .calendar-alert-yellow.active{
  border: 1px solid #fadb14;
}
.calendar-alert-overlay .calendar-alert-gray{
  background-color: #f0f0f0;
  border: 1px solid transparent;
}
.calendar-alert-overlay .calendar-alert-gray:hover, .calendar-alert-gray.active{
  border: 1px solid black;
}
.calendar-alert-overlay .calendar-alert-green:not(.inactive) .anticon{
  color: #3ba507;
  font-weight: bold; /*doesnt work on svg*/
}
.calendar-alert-overlay .calendar-alert-red:not(.inactive) .anticon{
  color: #f5222d;
  font-weight: bold; /*doesnt work on svg*/
}
.calendar-alert-overlay .calendar-alert-yellow:not(.inactive) span:nth-child(2){
  color: #e27500;
  font-weight: bold;
}
.calendar-alert-overlay .separator{
  background-color: #f0f0f0;
  width: 1px;
}

.calendar-modal-day-view .ant-table-row{
  background: #d9d9d9;
}
.calendar-modal-day-view .file-upload-entry{
  display: flex;
  gap: 10px;
  width: 100%;
  /*justify-content: space-between;*/
}
.calendar-modal-day-view .file-upload-entry.file-upload-matched:before{
  content: '\2713';
  display: inline-block;
  color: green;
}
.calendar-modal-day-view .file-upload-entry.file-upload-unmatched:before{
  content: '\2715';
  display: inline-block;
  color: red;
}

.weld-qc-report .fc-daygrid-event-harness{
  /* override blue border highlight */
  border-left: none;
}
.weld-qc-report .fc-daygrid-event{
  border-radius: 0;
  margin-left: 2px;
}
.weld-qc-report .event-indicator-fail{
  box-shadow: -2px 0px 0px 0px #f5222d;
}
.weld-qc-report .event-indicator-pass{
  box-shadow: -2px 0px 0px 0px #52c41a;
}
.weld-qc-report .event-indicator-not-tested{
  box-shadow: -2px 0px 0px 0px #fa8c16;
}
.weld-qc-report .event-indicator-no-data{
  box-shadow: -2px 0px 0px 0px #595959;
}

.pdf-preview{
  height: 100%;
  width: 100%;
}
.pdf-preview .react-pdf__Page{
  display: flex;
  justify-content: center;
  /* aspect-ratio attribute is relatively new (mid-2021) */
  aspect-ratio: 842/595;
}
.pdf-preview .react-pdf__Page > canvas, .pdf-preview svg{
  /* constrains preview within container using aspect ratio */
  /* note: prevents zooming in via browser */
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
.pdf-preview-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid black;
  /* gutter color taken from original lib https://projekty.wojtekmaj.pl/react-pdf/test/ */
  background-color: #525659;
  overflow: auto;
}
.pdf-preview-container > .pdf-page-nav{
  text-align: center;
  width: 200px;
  height: 60px;
  margin: 2px;
  background-color: #e5e5e5;
  color: #d4380d;
  font-weight: bold;
}
.react-pdf__Page__svg{
  /* this component is generated at .react-pdf__Page > (this) > svg */
  /* it is generated with direct width/height values that must be overridden */
  width: auto !important;
  height: auto !important;
}
.support-container{
  display: grid;
  grid-template: 5fr 3fr / 1fr 1fr;
  grid-template-areas: "form contact" "form .";
  grid-gap: 16px;
  margin: 0 16px;
}
.support-container a {
  text-decoration: underline;
}
.support-container-half {
  width: 50%;
  padding: 10px
}
.support-container-card {
  height: 100%;
}
.support-block {
  outline: 2px solid #f0f0f0;
  height: 100%;
}
.support-block {
  outline: 2px solid #f0f0f0;
}
.support-container >div:first-of-type {
  grid-area: form;
}
.support-container >div:last-of-type {
  grid-area: contact;
}
.support-container h1 {
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #082145;
  padding: 15px 0;
  margin: unset;
}
.support-container h2 {
  background-color: #f0f0f0;
  font-weight: 500;
  font-size: 14px;
  padding: 8px 16px;
  margin: 0;
}
.consent-form{
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
  padding: 24px;
}
.consent-form p{
  margin: 0;
}
.consent-form .form-item{
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 370px;
  margin-bottom: 10px;
}
.consent-form .form-item-checkbox{
  justify-content: flex-start;
  gap: 16px;
}
.consent-form .form-submit{
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 462.5px;
  height: 28px;
  font-size: 12px;
  gap: 8px;
}
.consent-form input[type=text], .consent-form input[type=email]{
  flex: 1 0 100px;
  max-width: calc(100% - 50px);
  border: 1px solid #f0f0f0;
}
.consent-form input[type=text]:hover, .consent-form input[type=text]:focus,
.consent-form input[type=email]:hover, .consent-form input[type=email]:focus{
  outline: 1px solid #1890ff;
}
.consent-form input[type=submit]{
  background-color: #3076b8;
  color: white;
  border: unset;
  padding: 3.5px 8px;
}
.consent-form input:disabled{
  color: #595959;
  background-color: #f0f0f0;
}
.consent-form input:disabled:hover{
  outline: none;
}
.consent-form .warn {
  color: #f5222d;
}
.consent-form .agree-text {
  font-style: italic;
  text-transform: capitalize;
}
.contact-info{
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.contact-info svg{
  margin-right: 8px;
}

.healthpage{
  width: 100%;
  /* height: 100%; */
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
}
.summarypage{
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 30px repeat(2, min-content);
  grid-gap: 20px;
}
.summarypage h1{
  grid-column: 1/3;
}
.summarypage .ant-descriptions{
  grid-column: 1/3;
}
.summarypage .ant-card-body{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.summarypage .usage{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
}
.summarypage .usage-values{
  text-align: center;
}
.summarypage .usage-values>:nth-child(1){
  font-size: 16px;
  font-weight: 500;
}
.summarypage .usage-values>:nth-child(2){
  font-size: 12px;
  font-weight: 400;
}
.usage-chart .ant-progress-text {
  color: rgba(0, 0, 0, 0.85) !important;
}
.alarmstatistics{
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 30px repeat(2, min-content);
  grid-gap: 20px;
}
.alarmstatistics .header{
  display: flex;
  justify-content: space-between;
}
.alarmstatistics .date-select-buttons{
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

/*requires specificity higher than (0,1,1) to override ant*/
.ant-btn.date-select-button-active{
  color: #40a9ff;
  border-color: #40a9ff;
}

.ant-skeleton.ant-skeleton-element.ant-skeleton-active {
  width: -webkit-fill-available;
  display: block;
}